import React from 'react';
// import logo from './logo.svg';
// import './App.css';

function NotFound() {
  return (
   <div className="page-container">
			<div className="page-content-wrapper">
				<div className="page-content">
					<div className="page-bar">
						<div className="page-title-breadcrumb">
							
						</div>
					</div>
					<div className="row">
						<div>
							<h1>404 Not Found</h1>
						</div>
					</div>
				</div>
			</div>
		</div>
  );
}

export default NotFound;
